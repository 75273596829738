import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/home.vue')
    },
    {
        path: '/contract',
        name: 'contract',
        component: () => import('../views/contract.vue')
    },
    {
        path: '/information',
        name: 'information',
        component: () => import('../views/information.vue')
    },
    {
        path: '/payment',
        name: 'payment',
        component: () => import('../views/payment.vue')
    },
    {
        path: '/sign',
        name: 'sign',
        component: () => import('../views/sign.vue')
    },
]

const router = new VueRouter({
                                 mode: 'hash',
                                 base: process.env.BASE_URL,
                                 routes
                             })

export default router
