import ElementUI from 'element-ui';
//样式文件需要单独引入
import 'element-ui/lib/theme-chalk/index.css';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
            el: '#app',
            router,
            store,
            render: h => h(App)
        }).$mount('#app')
